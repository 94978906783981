import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Container from '../styles/Container';
import HamburgerMenu from './HamburgerMenu';

const StyledHeader = styled.header`
  padding: 3rem 0 3rem;
  display: flex;
  justify-content: space-between;
  grid-gap: 1.5rem;
  a {
    display: flex;
    align-items: center;
  }
  nav {
    display: flex;
    justify-content: flex-end;
    a {
      margin: 0 1.5rem 0;
      font-family: var(--fontSecondary);
      font-size: 1.8rem;
      color: var(--black);
      text-decoration: none;
      &.active {
        position: relative;
        z-index: 1;
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 15px;
          background: var(--yellow);
          z-index: -1;
          top: 0.8rem;
          left: 0;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
    @media (max-width: 767.98px) {
      > a {
        display: none;
      }
    }
  }
  h1 {
    margin: 0;
    font-family: var(--fontSecondary);
    font-size: 2rem;
    color: var(--black);
  }
  @media (max-width: 767.98px) {
    padding: 2rem 0 2rem;
  }
`;

const Nav = () => (
  <Container>
    <StyledHeader>
      <Link to="/">
        <h1>Bruce Brophy</h1>
      </Link>
      <nav>
        <Link to="/about" partiallyActive activeClassName="active">
          About
        </Link>
        {/* <Link to="/projects" partiallyActive activeClassName="active">
          Projects
        </Link> */}
        <Link to="/blog" partiallyActive activeClassName="active">
          Blog
        </Link>
      </nav>
      <HamburgerMenu />
    </StyledHeader>
  </Container>
);

export default Nav;
