import { Link } from 'gatsby';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

const BurgerContainer = styled.div`
  display: none;
  margin-bottom: -6px;
  position: relative;
  @media (max-width: 767.98px) {
    display: block;
  }
`;

const StyledBurger = styled.button`
  border: 0;
  background: 0;
  cursor: pointer;
  svg {
    width: 3rem;
  }
  &:focus {
    outline: none;
  }
`;

const BurgerNav = styled.ul`
  position: absolute;
  z-index: 10;
  top: 25px;
  right: 0;
  padding: 1rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3);
  li.hamburger-item {
    display: block;
    margin-bottom: 0.75rem;
    width: 100%;
    font-family: var(--fontSecondary);
    font-size: 1.6rem;
    list-style: none;
    border-radius: 0.5rem;
    a {
      padding: 0.5rem 5rem 0.5rem 1.5rem;
      color: var(--black);
    }
    &:hover {
      background: var(--greyDarker);
    }
    &:last-child {
      margin: 0;
    }
  }
`;

const HamburgerMenu = () => {
  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      return;
    }

    // outside click
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <BurgerContainer ref={node}>
      <StyledBurger
        type="button"
        aria-label="mobile-navigation"
        onClick={e => setOpen(!open)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </StyledBurger>
      {open && (
        <BurgerNav>
          <li className="hamburger-item">
            <Link onClick={() => setOpen(!open)} to="/about">
              About
            </Link>
          </li>
          {/* <li className="hamburger-item">
            <Link onClick={() => setOpen(!open)} to="/projects">
              Projects
            </Link>
          </li> */}
          <li className="hamburger-item">
            <Link onClick={() => setOpen(!open)} to="/blog">
              Blog
            </Link>
          </li>
        </BurgerNav>
      )}
    </BurgerContainer>
  );
};

export default HamburgerMenu;
