import React from 'react';
import styled from 'styled-components';
import Container from '../styles/Container';

const StyledFooter = styled.footer`
  padding: 2rem 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
    font-family: var(--fontSecondary);
    font-size: 2rem;
    color: var(--black);
  }

  @media (max-width: 767.98px) {
    flex-direction: column;
    h2 {
      margin-bottom: 0.75rem;
    }
  }
`;

const FooterNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--fontSecondary);
  a {
    margin: 0 2rem;
    color: var(--black);
    &::first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Footer = () => (
  <Container>
    <StyledFooter>
      <h2>Bruce Brophy</h2>
      <FooterNav>
        <a rel="noreferrer noopener" href="https://github.com/brucebrophy">
          GitHub
        </a>
        <a rel="noreferrer noopener" href="https://twitter.com/brucebrophy">
          Twitter
        </a>
        <a
          rel="noreferrer noopener"
          href="https://linkedin.com/in/bruce-brophy"
        >
          LinkedIn
        </a>
      </FooterNav>
    </StyledFooter>
  </Container>
);

export default Footer;
