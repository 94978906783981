import React from 'react';
import styled from 'styled-components';
import GlobalStyles from '../styles/GlobalStyles';
import Header from './Header';
import Footer from './Footer';

const MainContent = styled.main`
  min-height: 100vh;
`;

const Layout = ({ children }) => (
  <>
    <GlobalStyles />
    <Header />
    <MainContent>{children}</MainContent>
    <Footer />
  </>
);

export default Layout;
