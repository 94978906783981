import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyles = createGlobalStyle`
  :root {
		// colors
    --blue: #1E63F2;
    --blueDarker: #1445ce;
    --yellow: #FFD306;
		--black: #2B2B2B;
		--grey: #F9F9F9;
		--greyDarker: #E7E7E7;

		// fonts
		--fontPrimary: 'Roboto';
		--fontSecondary: 'Roboto Mono';

		// devices 
		/* --xs: 575.98px;
		--sm: 767.98px;
		--md: 991.98px;
		--lg: 1199.98px; */
  }
  
  ${normalize}

  html {
    box-sizing: border-box;
    font-size: 10px;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    padding: 0;
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.6;
    font-family: var(--fontPrimary),-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    color: var(--black);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
	p {
		margin-top: 0;
	}
  a {
    color: 'black';
    text-decoration: none;
  }
`;

export default GlobalStyles;
