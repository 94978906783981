import styled from 'styled-components';

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 1050px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

export default Container;
